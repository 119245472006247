<template>
  <div class="container">
    <h4 class="text-center my-4">Список мероприятий</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">
      <DataTable :value="events" showGridlines stripedRows
                 :globalFilterFields="['name']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск мероприятия"/>
            </span>
          </div>
        </template>
        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{ index + 1 }}
          </template>
        </Column>
        <Column field="name" header="Название мероприятия"></Column>
        <Column field="name" header="Организация">
          <template #body="{data}">
            <p>{{organizations.find(i => i.id == data.organization_id).name}}</p>
          </template>
        </Column>
        <Column header="Место проведения">
          <template #body="{data}">
            <p v-if="data.location === null">{{ data.room_name }}</p>
            <p v-else>{{ data.location }}</p>
          </template>
        </Column>
        <Column header="Дата начала">
          <template #body="{data}">
            <p>{{data.start_date.substr(0, 16)}}</p>
          </template>
        </Column>
        <Column header="Дата конца">
          <template #body="{data}">
            <p>{{data.end_date.substr(0, 16)}}</p>
          </template>
        </Column>
        <Column field="student_event_registration" header="Количество зарегистрированных студентов" style="max-width: 250px"></Column>

        <Column header="Статус" style="width: 200px">
          <template #body="{data}">
            <div class="d-flex flex-row align-items-center justify-content-between">
              <p class="m-1" v-if="!data.isChanging">{{statuses.find(i => i.status_id === Number(data.status)).status_name}}</p>
              <select v-else :id="data.events_id" @change="setStatusId($event, data.events_id)">
                <option v-for="(status, index) of statuses" :key="index" :selected="status.status_id === Number(data.status)" :disabled="status.status_id === 0" :hidden="status.status_id === 0" :value="status.status_id">{{ status.status_name }}</option>
              </select>
              <button v-if="!data.isChanging" class="btn btn-secondary ms-1" @click="isStatusChanging(data.events_id)"><i class="pi pi-pencil"></i></button>
              <button v-else class="btn btn-primary ms-1" @click="sendStatusId(data.events_id)"><i class="pi pi-check"></i></button>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";

export default {
  name: "DispatcherEvents",
  data() {
    return {
      loading: true,
      statuses: [
        {status_id: 0, status_name: 'Ожидает'},
        {status_id: 1, status_name: 'Подтвержден'},
        {status_id: 2, status_name: 'Отказано'}
      ],
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      }
    }
  },

  methods: {
    ...mapActions('dmpEvents', ['GET_EVENTS', 'PUT_EVENTS_STATUS']),
    ...mapActions('organization', ['GET_ORGANIZATIONS']),
    ...mapMutations('dmpEvents', ['SET_EVENT_STATUS_ID']),

    isStatusChanging(id){
      this.events.find(i => i.events_id === id).isChanging = true
    },

    setStatusId(event, id){
      this.SET_EVENT_STATUS_ID({id: id, status_id: event.target.value})
      console.log(event.target.value)
    },

    async sendStatusId(id){
      if (this.PUT_EVENTS_STATUS(id)){
        this.$message({title: 'Успешно сохранено'})
      } else {
        this.$error({title: 'Данные не сохранились'})
      }
      this.events.find(i => i.events_id === id).isChanging = false
    }
  },

  computed: {
    ...mapState('organization', ['organizations']),
    ...mapState('dmpEvents', ['events']),
  },

  async mounted() {
    await this.GET_EVENTS();
    await this.GET_ORGANIZATIONS();
    for(let event of this.events){
      event.isChanging = false
    }
    this.loading = false
  }
}
</script>

<style scoped>

</style>